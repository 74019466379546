.mainpage {
  background-color: rgb(243, 248, 250);
}

.subpage {
  background-color: white;
}

.subpage2 {
  background-color: white;
  margin: 15px;
}

.pointer {
  cursor: pointer;
}

.ant-modal-footer {
  display: none;
}

.ant-select-selector {
  height: 50px !important;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
  margin-top: 5px;
}

.btn-square {
  border-radius: 0;
}

.footer-div {
	background:linear-gradient(to bottom, #f7f9fa 0%, #cff4fc 100%);
}

.header-div {
	background:linear-gradient(to bottom, #cff4fc 0%, #f7f9fa 100%);
}

.header-row {
  max-width: 50rem;
}

.nav-link:hover {
	background:linear-gradient(to bottom, #f7f9fa 0%, #cff4fc 100%);
}

.user-card {
	box-shadow: 0px 0px 0px 2px #ffffff;
	background:linear-gradient(to bottom, #f7f9fa 5%, #bbeaf5 100%);
	cursor:pointer;
}
.user-card:hover {
	background:linear-gradient(to bottom, #bbeaf5 5%, #f7f9fa 100%);
	background-color:#f7f9fa;
}

.page-title {
	margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 800;
  color: rgb(4, 31, 82);
  font-size: 2.5rem;
	text-shadow: 4px 4px 3px #4f6296;
}

.blue-nav-text {
  color: rgb(59, 90, 148);
}

.dull-red-text {
  color: rgb(200, 90, 90);
}

.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 300px;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
.centered-text {  
  text-align: center;
}
[
  data-theme="dark"] .demo-infinite-container {
  border: 1px solid #303030;
}

.gps-img-preview {
  height: 155px;
  max-height: 150px;
  max-width: 150px;
  width: 155px; 
  border-width: 5px;
}

.drop-target {
  cursor: alias;
  /* Change to move, no-drop, alias, etc. as needed */
}

.gallery-container {
  overflow: hidden;
  position: relative;
}

.gallery-slider {
  position: absolute;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

h6 {
  margin-left: -3px;
  margin-right: 3px;
}

h5 {
  margin-left: -3px;
  margin-right: 3px;
  font-weight: 500;
  line-height: 0.8;
  font-size: 1.0rem;
}

.imageLogo {
  position: absolute;
  top: 0;
  left: 0;
}

.iconMainMenu {
  position: relative; 
  top: -3px;
}